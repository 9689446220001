import {inject, injectable} from 'inversify'
import {Service} from '@/common/dependency.configs'
import HttpHandler from '@/common/services/connect/HttpHandler'
import type RestResponse from '@/common/data/RestResponse'

@injectable()
@Service
export class ProductsSubgroupTaxplanService {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  get(taxPlanId: string): Promise<RestResponse<ProductsSubgroupTaxplan>> {
    return this.httpHandler.get(`{{boltsbranch}}/tax-plan/${taxPlanId}`)
  }

  put(taxPlanId: string, dto: ProductsSubgroupTaxplan): Promise<RestResponse<ProductsSubgroupTaxplan>> {
    return this.httpHandler.put(`{{boltsbranch}}/tax-plan/${taxPlanId}`, dto)
  }
}


export interface ProductsSubgroupTaxplan {
  typeCode: string
  subgroupCode: string
  enabled: boolean
  typeDescription: string
  displayOrder: number
  disclosureCode?: string
  previousYearContribCutoffDate: string
  validateFundingIntentionContribLimits: boolean
  validateFundingContribLimits: boolean
  currentYearContribLimit: number
  previousYearContribLimit: number
  currentYearCatchupContribLimit: number
  previousYearCatchupContribLimit: number
  currentYearCatchupAge: number
  previousYearCatchupAge: number
}
