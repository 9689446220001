import {Service} from '@/common/dependency.configs'
import {injectable} from 'inversify'
import {isNull, isObject, isString, isUndefined} from 'lodash'
import isNumber from "lodash.isnumber";

@injectable()
@Service
export class BltSearch {
  /**
   * @param list - An unfiltered list of items
   * @param searchProperties - An array of either strings or an object containing options
   * @param searchTerm - The search term to search for
   */
  for<T extends SearchableItem>(
    list: Array<T> | Readonly<Array<T>>,
    searchProperties: Array<SearchFactoryOption<T> | keyof T>,
    searchTerm: string | Readonly<string>
  ): Array<T> {
    const searchProps = searchProperties.map((searchProp) => {
      if (isObject(searchProp)) {
        return searchProp
      } else {
        return {
          targetName: searchProp as string,
          caseSensitive: false
        }
      }
    })

    return list.filter((item) => {
      return searchProps.some((x) => this.itemExists(item, x, searchTerm))
    })
  }

  itemExists<T extends SearchableItem>(item: T, searchOption: SearchFactoryOption<T>, searchTerm: string): boolean {
    const caseSensitive = searchOption.caseSensitive ?? false

    if (isNull(item[searchOption.targetName])) {
      return false
    } else if (isString(item[searchOption.targetName]) || isUndefined(item[searchOption.targetName])) {
      return caseSensitive
        ? item[searchOption.targetName]?.includes(searchTerm)
        : item[searchOption.targetName]?.toLowerCase()?.includes(searchTerm.toLowerCase())
    } else if (isNumber(item[searchOption.targetName])) {
      return item[searchOption.targetName]?.toString()?.includes(searchTerm)
    } else {
      console.error(`SearchFactory tried to search for a key of ${searchOption.targetName as string}, but that was not a string or number`)
      return false
    }
  }
}

export interface SearchFactoryOption<T> {
  targetName: keyof T
  caseSensitive: boolean
}

interface SearchableItem {
  [key: string]: any | undefined
}
