<script setup lang="ts">
import {requireInject} from "@/common/utils/InjectUtil";
import {PRODUCT_SELECTION_INJECTION} from "@/common/services/Products/productSelectionInjection";
import {computed} from "vue";

const injection = requireInject(PRODUCT_SELECTION_INJECTION)

const tfaAccounts = computed(() => {
  return injection.values.accountList.value.filter(account => {
    const group = injection.values.productList.value.find((product) => product.productId === account.accountTypeId)
    return group?.group == 'TFA'
  })
})

</script>
<template>
  <pre>
    This will be the funding intention page for TFA accounts
  </pre>
</template>