import type {DeepReadonly, InjectionKey, Ref} from 'vue'
import type TypeCode from '@/common/data/TypeCode'
import type {ProductSubgroup} from '@/common/services/Products/productsSubgroupService'
import type {Product} from '@/common/models/IProductSelection'
import type {ProductsSubgroupTaxplan} from "@/common/services/Products/productsSubgroupTaxplanService";
import type IAccount from "@/common/models/IAccount";

interface ProductSelectionInjection {
  values: {
    productList: DeepReadonly<Ref<Array<Product>>>
    searchTerm?: DeepReadonly<Ref<string>>
    accountList: DeepReadonly<Ref<Array<IAccount>>>
    productGroups: DeepReadonly<Ref<Array<TypeCode>>>
    productSubgroups: DeepReadonly<Ref<Map<string, Array<ProductSubgroup>>>>
    productSubgroupTaxPlans: DeepReadonly<Ref<Map<string, ProductsSubgroupTaxplan>>>
    selectedSubgroup: Ref<ProductSubgroup | undefined>
  }
  methods: {
    goToPane: (pane: ProductSelectionPanes) => void
    addProduct: (product: Product) => Promise<IAccount>
    deleteAccount: (account: DeepReadonly<IAccount>) => Promise<void>
    updateAccount?: (account: DeepReadonly<IAccount>) => Promise<IAccount>
  }
  channel: 'open' | 'branch'
}

export type ProductSelectionPanes = 'PRODUCT_GROUPS' | 'PRODUCT_SUBGROUP' | 'SELECTED_ACCOUNT_LIST' | 'FUNDING_INTENTION'

export const PRODUCT_SELECTION_INJECTION: InjectionKey<ProductSelectionInjection> = Symbol('product-selection-state')
