import {inject, injectable} from 'inversify'
import {Service} from '@/common/dependency.configs'
import HttpHandler from '@/common/services/connect/HttpHandler'
import type RestResponse from '@/common/data/RestResponse'
import type TypeCode from '@/common/data/TypeCode'
import type {Ref} from '@vue/reactivity'
import type {InjectionKey} from 'vue'

@injectable()
@Service
export class ProductsSubgroupService {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  get(productGroup: string): Promise<RestResponse<Array<ProductSubgroup>>> {
    return (
        this.httpHandler
            .get(`{{boltsbranch}}/product-group/${productGroup}/subgroup`)
    )
  }

  put(productGroup: TypeCode, productSubgroup: ProductSubgroup): Promise<RestResponse<ProductSubgroup>> {
    return this.httpHandler.put(
        `{{boltsbranch}}/product-group/${productGroup.typeCode}/subgroup/${productSubgroup.typeCode}`,
        productSubgroup
    )
  }
}

export interface ProductSubgroup {
  typeCode: string
  typeDescription: string
  accountTypeGroupCode: string
  enabled: boolean
  taxPlanCode: string
}
